<template>
  <div>
    <div class="menuWidth">
      <div class="main-card mb-3 card">
        <div class="cc">
          <div class="grid-menu grid-menu-3col">
            <div class="no-gutters row">
              <div class="col-sm-4 col-xl-4" style="width:50% !important;">
                <button :disabled="this.loading" @click="reprintInvoice"
                        class="btn-icon-vertical btn-square btn-transition btn btn-primary"
                        v-if="!loadingReprint"><i
                    class="lnr-printer btn-icon-wrapper"> </i><b>{{ $t('generic.lang_reprint')}}</b>
                </button>

                <div class="text-center btn-icon-wrapper" style="margin-top: 25px;" v-else>
                  <v-progress-circular color="primary" indeterminate width="3"></v-progress-circular>
                </div>
              </div>
              <div class="col-sm-4 col-xl-4" style="width:50% !important;">
                <button :disabled="this.loading" @click="openDialog"
                        class="btn-icon-vertical btn-square btn-transition btn btn-secondary"><i
                    class="lnr-envelope btn-icon-wrapper"> </i>
                  <b>{{ $t('generic.lang_byEmail') }}</b>
                </button>
              </div>
              <div class="col-sm-4 col-xl-4" style="width:50% !important;">
                <button :disabled="this.loading" @click="downloadInvoicePDF"
                        class="btn-icon-vertical btn-square btn-transition btn btn-success"
                        v-if="!loadingDownloadInvoice">
                  <i class="lnr-download btn-icon-wrapper"></i>
                  <b>{{ $t('generic.lang_download') }}</b>
                </button>

                <div class="text-center btn-icon-wrapper" style="margin-top: 25px;" v-else>
                  <v-progress-circular color="primary" indeterminate width="3"></v-progress-circular>
                </div>
              </div>
              <div v-if="this.invoiceData.invoiceType != 3" class="col-sm-4 col-xl-4"
                   style="width:50% !important;">
                <button :disabled="this.loading" @click="downloadDeliveryPDF"
                        class="btn-icon-vertical btn-square btn-transition btn btn-info"
                        v-if="!loadingDownloadDelivery">
                  <i class="lnr-download btn-icon-wrapper"></i>
                  <b>{{ $t('erp.lang_receiptDisplayDeliveryNote') }}</b>
                </button>
                <div class="text-center btn-icon-wrapper" style="margin-top: 25px;" v-else>
                  <v-progress-circular color="primary" indeterminate width="3"></v-progress-circular>
                </div>
              </div>
              <div
                  v-if="this.invoiceData.invoiceType != 3 && $store.getters['permissions/checkHybridEnabled']('Hybrid') && $store.getters['permissions/checkPermission']('allowVoidItems')"
                  class="col-sm-4 col-xl-4"
                  style="width:50% !important;">
                <button :disabled="this.loading||loadingReActiveSale" :loading="loading||loadingReActiveSale"
                        @click="checkTSEReactivateOrder()"
                        class="btn-icon-vertical btn-square  br-bl btn-transition btn btn-warning"><i
                    class="lnr-redo btn-icon-wrapper"> </i><b>{{ $t('erp.lang_reactivateSale') }}</b>
                </button>
              </div>
              <div
                  v-if="this.invoiceData.invoiceType != 3 && $store.getters['permissions/checkHybridEnabled']('Hybrid') && $store.getters['permissions/checkPermission']('allowVoidItems')"
                  class="col-sm-4 col-xl-4"
                  style="width:50% !important;">
                <button @click="voidInvoice" :disabled="this.loading"
                        class="btn-icon-vertical btn-square btn-transition btn btn-danger"><i
                    class="lnr-warning btn-icon-wrapper"> </i>
                  <b>{{ $t('erp.lang_delete_order') }}</b>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="invoice-box">
      <table cellpadding="0" cellspacing="0">
        <tr class="top">
          <td colspan="4">
            <table>
              <tr>
                <td class="title">
                  <img :src="invoiceLogoUrl"
                       style="width:100%; max-width:300px;">
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr class="information">
          <td colspan="4">
            <table>
              <tr>
                <td style="text-align:left !important;text-decoration: underline;">
                  {{ invoiceSenderAddress }}
                </td>
                <td>
                  <v-btn
                      v-if="this.invoiceData.invoiceType == 3"
                      @click="openRefInvoice"
                      color="warning"
                      text
                  >{{ $t("accounting.lang_OpenOriginalReceipt") }}
                  </v-btn>
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr class="information">
          <td colspan="4">
            <table>
              <tr>
                <td>
                  <!-- CUSTOMER DATA -->
                  <div v-if="invoiceCustomerData.id > 0">
                    <b>{{ $t('accounting.lang_InvoiceRecipient') }}:</b><br>
                    <span v-if="invoiceCustomerData.company !== null">{{
                        invoiceCustomerData.company
                      }}<br></span>
                    {{ invoiceCustomerData.first_name }}
                    {{ invoiceCustomerData.last_name }}<br>
                    {{invoiceCustomerData.street_no }} {{invoiceCustomerData.number }}<br>{{ invoiceCustomerData.zip }}
                    {{ invoiceCustomerData.city }}<br>
                    {{ invoiceCustomerData.country }}
                  </div>
                  <!-- CHECK SIHOT PAYLOAD -->
                  <div v-if="$store.getters['permissions/checkModule'](46) && pmsPayload !== null">
                    <b>SIHOT {{ $t('generic.lang_customer') }}</b>
                    <span v-if="pmsPayload.guestData !== null"><br>{{
                        pmsPayload.guestData.adress
                      }} {{ pmsPayload.guestData.firstname }} {{ pmsPayload.guestData.lastname }}</span>
                    <br><br>{{ $t('accounting.lang_zimmer') }}: {{ pmsPayload.roomID }}
                    <br>{{ $t('accounting.langAccountingAccount') }}: {{ pmsPayload.accountno }}
                  </div>
                  <br>
                  <h2 v-if="this.invoiceData.invoiceType != 3">{{ $t('accounting.lang_bill') }}</h2>
                  <h2 v-if="this.invoiceData.invoiceType == 3">{{ $t('generic.lang_retour_checkout') }}</h2>
                </td>
                <!-- SHIPPING DATA -->
                <td v-if="invoiceShippingData.id > 0">
                  <div>
                    <b>{{ $t('accounting.lang_shipTo') }}:</b><br>
                    <span v-if="invoiceShippingData.company !== null">{{
                        invoiceShippingData.company
                      }}<br></span>
                    {{ invoiceShippingData.first_name }}
                    {{ invoiceShippingData.last_name }}<br>{{
                      invoiceShippingData.street_no
                    }}<br>{{ invoiceShippingData.zip }}
                    {{ invoiceShippingData.city }}
                  </div>
                </td>

                <td>
                  {{ $t('accounting.lang_invoiceID') }}: {{ invoiceNo }}<br>
                  {{ $t('accounting.lang_invoiceOrderNo') }}: {{ orderNo }}<br>
                  {{ $t('generic.lang_cashierID') }}: {{ invoiceCashierId }}<br>
                  {{ $t('accounting.lang_dateOfInvoice') }}: {{ saleTimeStamp }}<br>
                  {{ $t('accounting.lang_dateOfDelivery') }}: {{ deliveryTimeStamp }}<br>
                  <span v-if="invoicePaidTimeStamp.length < 5">{{ $t('accounting.lang_paymentDue') }}: {{ invoiceDueTimeStamp }}</span>
                  <span v-if="invoicePaidTimeStamp.length > 5">{{ $t('accounting.lang_paymentMade') }}: {{ invoicePaidTimeStamp }}</span>
                </td>
              </tr>
            </table>
          </td>
        </tr>

      </table>

      <b-table :fields="fields" :items="computedInvoicePositions"
               hover
               striped :table-variant="this.$vuetify.theme.dark? 'dark' : ''">
        <template #cell(descr)="data">
          {{ data.item.descr + '\n' }}
          <template v-if="data.item.hasOwnProperty('bookedGarnish')">
            <span v-for="(garnish,index) in data.item.bookedGarnish" :key="index" class="pa-0 ma-0 d-block"
                  style="font-size: 12px;line-height: 1.2;">
              &#9656;{{ garnish.qty + 'x' + garnish.name }}&emsp;&emsp;{{
                garnish.priceItem | currency
              }} / {{ garnish.priceStack | currency }}
            </span>
          </template>
        </template>
      </b-table>
      <br>
      <span style="float:right;"><h4>{{ $t('generic.lang_subtotal') }}: {{ invoiceTotalNet | currency }}</h4></span><br
        style="clear:both;">
      <span style="float:right;"><h4>{{ $t('erp.lang_mwst') }}: {{ invoiceTotalTax | currency }}</h4></span><br
        style="clear:both;">
      <span style="float:right;"><h4
          style="font-weight:bold;">{{ $t('generic.lang_total') }}: {{ invoiceTotal | currency }}</h4></span>

      <h4>{{ $t('accounting.lang_InvoiceNotes') }}</h4>
      <h6>{{ invoiceFreeText }}</h6>

      <br style="clear:both;">

    </div>
    <!-- TSE ERROR DIALOG -->
    <v-dialog :value="tseErrorMessage.length > 0" persistent width="400">
      <v-card>
        <v-card-title>{{ $t('generic.lang_error') }}</v-card-title>
        <v-card-text v-text="tseErrorMessage"></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="closeTSEErrorDialog">{{ $t('generic.lang_understand') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                          :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidth"
                          id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
    </div>
    <!-- Invoice not empty dialog -->
    <v-dialog v-model="showDialog" max-width="300">
      <v-card>
        <v-card-title>Info</v-card-title>
        <v-card-text class="text-center">
          {{ $t('accounting.lang_pleaseCompleteTheSaleInThe') + ' ' + posType + ' ' + $t('accounting.lang_posFirstToReActiveAnInvoice') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="showDialog = false">{{ $t('generic.lang_understand') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- INVOICE PDF DIALOG -->
    <v-dialog persistent v-model="displayInvoiceDialog" width="650">
      <v-card>
        <v-card-text style="height: 600px;">
          <iframe :src="iframePDFContent" height="550" v-if="iframePDFContent.length > 0" width="600"/>
        </v-card-text>

        <v-card-actions :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : 'background-color: white;'">
          <v-spacer></v-spacer>
          <v-btn @click="displayInvoiceDialog = false" color="blue darken-1" dark text>{{ $t('generic.lang_close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--email dialog -->
    <v-card-text>
      <v-dialog max-width="600px" persistent v-model="dialog">
        <v-form lazy-validation ref="emailForm">
          <div class="card">
            <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header">
              {{ $t('accounting.lang_sendingInvoice').replace("{invoiceNo}", invoiceNo) }}
            </div>

            <div class="card-body" :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''">
              <v-row>

                <v-col class="pb-0 mb-0" cols="12">
                  <template v-for="(email,k) in form.emails">
                    <v-chip class="ma-1"
                            :key="k"
                            label
                            small
                            :color="email.color"
                    >
                      <span class="pr-2">{{ email.text }}</span>
                      <v-icon
                          @click="form.emails.splice(k,1)"
                          small
                      >
                        delete
                      </v-icon>
                    </v-chip>
                  </template>
                </v-col>
                <v-col class="pb-0 mb-0" cols="12">
                  <v-menu
                      :close-on-content-click="false" :close-on-click="false"
                      transition="scale-transition"
                      offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                          outlined
                          v-model="searchEmail"
                          dense
                          :label="$t('accounting.lang_InvoiceRecipient')"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          @focus="showTouchKeyboard"
                          @keypress.enter="addEmail"
                          v-on="on"
                          no-resize
                      >
                      </v-text-field>
                    </template>
                    <v-card width="100%">
                      <v-list-item class="transparent" @click="addEmail">
                        <v-chip
                            :color="`primary lighten-3`"
                            label
                            small
                        >
                          {{ searchEmail }}
                        </v-chip>
                      </v-list-item>
                    </v-card>
                  </v-menu>

                  <!--
                                    <v-combobox
                                        :data-layout="KEYBOARD.KEYSETS.NORMAL" :filter="filter"
                                        :hide-no-data="!searchEmail"
                                        :items="(invoiceCustomerData&&invoiceCustomerData.email)?[invoiceCustomerData.email]:[]"
                                        :rules="[v => !!v]"
                                        :search-input.sync="searchEmail"
                                        @focus="showTouchKeyboard"
                                        autofocus
                                        clearable
                                        dense
                                        hide-selected
                                        :label="$t('accounting.lang_InvoiceRecipient')"
                                        multiple
                                        outlined
                                        small-chips
                                        v-model="form.emails"
                                    >
                                      <template v-slot:no-data>
                                        <v-list-item class="transparent">
                                          <v-chip
                                              :color="`primary lighten-3`"
                                              label
                                              small
                                          >
                                            {{ searchEmail }}
                                          </v-chip>
                                        </v-list-item>
                                      </template>
                                      <template v-slot:selection="{ attrs, item, parent, selected }">

                                      </template>

                                    </v-combobox>
                                    -->
                </v-col>

                <v-col class="pt-0 mt-0 pb-0 mb-0" cols="12">
                  <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :rules="[v => !!v]"
                                @focus="showTouchKeyboard"
                                clearable dense :label="$t('settings.lang_mail_subject')" outlined
                                v-model="form.subject"/>
                </v-col>

                <v-col class="pt-0 mt-0" cols="12">
                  <quill-editor :data-layout="KEYBOARD.KEYSETS.NORMAL" :options="quillOptions"
                                :rules="[v => !!v]" @focus="showTouchKeyboard"
                                v-model="form.message"></quill-editor>
                </v-col>


              </v-row>
            </div>

            <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-footer text-right pa-1 pr-3">
              <v-spacer/>
              <v-btn @click="dialog = false" class="ma-1" color="error">
                {{ $t('generic.lang_cancel') }}
              </v-btn>
              <v-btn :disabled="sendLoader" :loading="sendLoader" @click="send" class="ma-1"
                     color="primary">
                {{ $t('support.lang_supportSend') }}
              </v-btn>
            </div>
          </div>
        </v-form>
      </v-dialog>
    </v-card-text>
  </div>

</template>

<style scoped>
.menuWidth {
  max-width: 1200px;
  margin: auto;
  margin-bottom: -22px;
}

.invoice-box {
  background-color: #ffffff;
  max-width: 1200px;
  margin: auto;
  padding: 30px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #555;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(n + 2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.item input {
  padding-left: 5px;
}

.invoice-box table tr.item td:first-child input {
  margin-left: -5px;
  width: 100%;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

.invoice-box input[type="number"] {
  width: 60px;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/** RTL **/
.rtl {
  direction: rtl;
  font-family: Tahoma, "Helvetica Neue", "Helvetica", Helvetica, Arial,
  sans-serif;
}

.rtl table {
  text-align: right;
}

.rtl table tr td:nth-child(2) {
  text-align: left;
}

</style>

<script>
import FileSaver from 'file-saver';
import {ENDPOINTS} from '../../config'
import {Events} from "../../plugins/events";
import mixin from "../../mixins/KeyboardMixIns";
import {mapGetters, mapState} from "vuex";
import {quillEditor} from 'vue-quill-editor';
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {createInvoicePrintingData} from "../../plugins/printing/printInvoice";
import {createRetourePrintingData} from "../../plugins/printing/printRetoure";
import randomString from "randomstring";
import {printDataFromPrinter} from "../../plugins/printing/printerController";
import moment from "moment";
import {geol} from "@/main";

export default {
  components: {quillEditor},

  mixins: [mixin],

  data() {
    return {
      ENDPOINTS,
      id: null,
      invoiceUID: "",
      items: [],
      search: "",
      loading: false,
      loadingDownloadInvoice: false,
      loadingDownloadDelivery: false,
      loadingReprint: false,
      invoiceData: {},
      invoicePositions: [],
      invoiceCustomerData: [],
      invoiceShippingData: [],
      invoicePosData: [],
      invoiceNo: null,
      orderNo: null,
      invoiceCashierId: null,
      invoiceUUID: null,
      invoiceNoReal: null,
      saleTimeStamp: null,
      invoiceType: null,
      invoiceTotal: 0.00,
      invoiceTotalNet: 0.00,
      invoiceTotalTax: 0.00,
      invoiceFreeText: "",
      shippingType: null,
      retourOriginalInvoiceID: "",
      cashierID: null,
      invoicePaidTimeStamp: "",
      invoiceDueTimeStamp: "",
      returnedReactiveInvoiceId: null,
      returnedReactiveInvoiceItems: [],
      reactivatePaymentType: 0,
      returnedReactiveInvoiceorderId: null,
      returnedReactiveInvoiceorderParty: null,
      deliveryTimeStamp: "",
      invoicePositionObj: [],
      displayInvoiceDialog: false,
      iframePDFContent: "",
      invoiceLogoUrl: "",
      invoiceSenderAddress: "",
      showDialog: false,
      posType: '',
      posVersion: '',
      loadingReActiveSale: false,
      additionalJSON: null,
      //email
      form: {
        emails: [],
        subject: "",
        message: "",
      },
      sendLoader: false,
      dialog: false,
      quillOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            [{'align': []}],
            [{'font': []}],
          ],
        },
      },

      //
      activator: null,
      attach: null,
      colors: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange'],
      editing: null,
      index: -1,
      menu: false,
      model: [],
      x: 0,
      searchEmail: null,
      y: 0,
      //TSE
      tseDevice: null,
      tseErrorMessage: "",
      tseErrorDialogButton: ""
    }
  },

  computed: {
    ...mapState([
      'settings',
      'api',
      'pos',
    ]),
    ...mapState('printer', {
      printers: state => state.printers
    }),
    ...mapGetters({
      fiscalClient: 'tse/fiscalClient',
      activeUser: 'multiUser/activeUser',
    }),
    fields() {
      return [
        {
          key: "pos",
          sortable: false,
          label: 'Pos.'
        },
        {
          key: "descr",
          sortable: false,
          label: this.$i18n.t('generic.lang_description')
        },
        {
          key: "bookedGarnish",
          sortable: false,
          thClass: 'd-none',
          tdClass: 'd-none',
          trClass: 'd-none',
        },
        {
          key: "qty",
          sortable: false,
          label: this.$t('erp.lang_QTY')
        },
        {
          key: "unitPrice",
          sortable: false,
          label: this.$t('erp.lang_ware_UnitPrice'),
          headerTitle: this.$t('generic.lang_ware_UnitPrice')
        },
        {
          key: "total",
          sortable: false,
          label: this.$t('generic.lang_total')
        }
      ]
    },
    invoicePrinter() {
      return this.printers.find((printer) => {
        if (printer.cashierID.includes(this.api.auth.cashierID)) {
          if (printer.type === 1) {
            return true;
          }
        }
      });
    },
    computedInvoicePositions() {
      let items = this._.cloneDeep(this.items);

      return items.map((item) => {
        item.total = this.$options.filters.currency(item.total);
        item.unitPrice = this.$options.filters.currency(item.unitPrice);
        return item;
      });
    },
    pmsPayload() {
      if (this.additionalJSON === null)
        return null;

      if (this.$store.getters["permissions/checkModule"](46)) {
        if (this.additionalJSON.hasOwnProperty("sihot"))
          if (!this.additionalJSON.sihot.payAsGuest)
            return this.additionalJSON.sihot;
      }

      return null;
    }
  },
  watch: {},
  mounted() {
    this.getData();

    // since the geol.local can change when ever werefresh the browser we need to set it every time we need to use it
    // we re gonna use this work arround for now until we find a better way
    if (this.$store.getters['settings/getSettingValue']('geoloc_systemcountry')) {
        geol.locale = this.$store.getters['settings/getSettingValue']('geoloc_systemcountry');
    }
  },

  methods: {
    addEmail() {
      if (this.searchEmail) {
        this.form.emails.push({
          text: this.searchEmail,
          color: this.colors[Math.floor(Math.random() * 6)],
        });
        this.searchEmail = null
      }
    },
    send() {
      if (!this.$refs.emailForm.validate()) return;
      this.sendLoader = true;

      let data = {};
      Object.assign(data, this.form);
      data.emails = this.form.emails.map((x) => x.text);
      data.invoiceID = this.$route.params.id;
      this.axios.post(ENDPOINTS.EMAILS.INVOICES, data).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_success'),
            color: "success"
          });

          this.dialog = false;
          this.$refs.emailForm.reset();
          this.form.message = "";
          this.form.emails = [];
          this.searchEmail = this.invoiceCustomerData.email;
        } else if (res.data.STATUS === "FAILED") {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.sendLoader = false;
      })
    },
    filter(item, queryText, itemText) {
      if (item.header) return false

      const hasValue = val => val != null ? val : ''

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
    },
    getColor(currentQTY, minQTY, standQTY) {
      if (currentQTY <= minQTY)
        return "error";
      else if (currentQTY >= minQTY && currentQTY < standQTY)
        return "warning";
      else return "success";
    },
    openDialog() {
      this.form.subject = "invoice " + this.invoiceNo + ' | ' + this.invoiceSenderAddress;
      this.dialog = true;
      if (this.invoiceCustomerData.email && this.invoiceCustomerData.email.length > 0) {
        this.$nextTick(() => {
          this.form.emails = [];
          this.searchEmail = this.invoiceCustomerData.email;
        })
        this.$forceUpdate()
      }

    },
    goBack() {
      this.$router.push('/accounting/SearchInvoices');
    },
    openRefInvoice() {
      this.$router.push({
        name: 'accounting.DisplayInvoice',
        params: {id: this.retourOriginalInvoiceID}
      }).then(() => this.getData());
    },
    downloadInvoicePDF() {
      this.loadingDownloadInvoice = true;
      this.axios.post(ENDPOINTS.ACCOUNTING.DISPLAYINVOICE.PRINTA4, {
            invoiceID: this.invoiceUID
          },
          {
            responseType: 'arraybuffer',
          }).then((res) => {


        //GENERATE PDF CONTENT FOR iFRAME
        const blobContent = new Blob([res.data], {type: "application/pdf"});
        this.iframePDFContent = URL.createObjectURL(blobContent);

        this.displayInvoiceDialog = true;

        //DOWNLOAD INVOICE AS PDF
        FileSaver.saveAs(new Blob([res.data], {
          type: "application/pdf"
        }), "Rechnung" + this.invoiceNoReal + ".pdf");
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.loadingDownloadInvoice = false;
      })
    },
    downloadDeliveryPDF() {
      this.loadingDownloadDelivery = true;
      this.axios.post(ENDPOINTS.ACCOUNTING.DISPLAYDELIVERY.PRINTA4, {
            invoiceID: this.invoiceUID
          },
          {
            responseType: 'arraybuffer',
          }).then((res) => {


        //GENERATE PDF CONTENT FOR iFRAME
        const blobContent = new Blob([res.data], {type: "application/pdf"});
        this.iframePDFContent = URL.createObjectURL(blobContent);

        this.displayInvoiceDialog = true;

        //DOWNLOAD INVOICE AS PDF
        FileSaver.saveAs(new Blob([res.data], {
          type: "application/pdf"
        }), "Lieferschein" + this.invoiceNoReal + ".pdf");
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.loadingDownloadDelivery = false;
      })
    },


    addZVTPrinting(printData) {

      // --------------------------- EC PRINTING --------------------------
      if (this.invoiceData.ecTermReceiptJSON && this.invoiceData.ecTermReceiptJSON.printing) {

        if (this.invoiceData.ecTermReceiptJSON.printing.customer) {
          // printData.push({cut: true});
          // REMOVE LAST CUT
          if (this.invoiceData.ecTermReceiptJSON.printing.customer.length > 1) {
            let lastElement = printData[(printData.length - 1)];
            if (lastElement.hasOwnProperty("cut"))
              this.$delete(printData, (printData.length - 1));

            printData.push({align: 'center'});
            printData.push({feed: 2});

            // LOOP EC PRINTING DATA
            this.invoiceData.ecTermReceiptJSON.printing.customer.forEach((printElement) => {
              if (printElement.trim().length === 0)
                return;

              printData.push({text: printElement});
            });

            printData.push({feed: 4});

          }
        }

        // HÄNDLERBELEG
        if (this.invoiceData.ecTermReceiptJSON.printing.salesperson) {
          printData.push({cut: true});
          if (this.invoiceData.ecTermReceiptJSON.printing.salesperson.length > 1) {
            printData.push({feed: 2});

            // LOOP EC PRINTING DATA
            this.invoiceData.ecTermReceiptJSON.printing.salesperson.forEach((printElement) => {
              if (printElement.trim().length === 0)
                return;

              printData.push({text: printElement});
            });

            printData.push({feed: 4});
            printData.push({cut: true});
          }
        }
      }
      // ---------------------------------------------------------------

      return printData;
    },

    addCMIPrinting(printData) {
      // --------------------------- EC PRINTING --------------------------
      if (this.invoiceData.ecTermReceiptJSON.hasOwnProperty("customer_receipt")) {
        // printData.push({cut: true});
        // REMOVE LAST CUT
        if (this.invoiceData.ecTermReceiptJSON.customer_receipt.length > 1) {
          let lastElement = printData[printData.length - 1];
          if (!lastElement.hasOwnProperty("cut"))
            this.$delete(printData, printData.length - 1);

          // LOOP EC PRINTING DATA
          printData.push(...this.invoiceData.ecTermReceiptJSON.customer_receipt)
        }
      }

      // HÄNDLERBELEG
      if (this.invoiceData.ecTermReceiptJSON.hasOwnProperty("merchant_receipt")) {
        if (this.invoiceData.ecTermReceiptJSON.merchant_receipt.length > 1) {
          // LOOP EC PRINTING DATA
          printData.push(...this.invoiceData.ecTermReceiptJSON.merchant_receipt)

        }
      }
      // ---------------------------------------------------------------

      return printData;
    },

    reprintInvoice() {
      this.loadingReprint = true;
      var printData;

      // PRINT INVOICE
      if (this.invoiceData.invoiceType === 1 || this.invoiceData.invoiceType === 2) {
        // RE-MAP INVOICE POSITIONS
        let invoicePositions = this.items.map((invoiceItem) => {
          return {
            amount: invoiceItem.qty,
            ean: invoiceItem.ean,
            name: invoiceItem.descr,
            taxValue: invoiceItem.tax,
            sellPrice: invoiceItem.unitPrice,
            originalSellPrice: invoiceItem.originalSellPrice,
            isVoid: 0, // TO DISABLE WRONG  PRINTOUT AFTER VOIDING
            discount: invoiceItem.discount,
            selectedExtras: []
          };
        });

        // GOODSVALUE
        let goodsValue = 0;

        invoicePositions.forEach((item) => {
          //CHECK IF VOIDED
          if (item.isVoid || item.amount === 0)
            return;

          if (item.sellPrice < 0)
            return;

          goodsValue += parseFloat((item.sellPrice * item.amount).toFixed(2));
        }, 0);

        // VOUCHERS VALUE
        let vouchersValue = 0;

        invoicePositions.forEach((item) => {
          if ((item.ean.includes("V$") || item.ean.includes("GF$")) && !item.isVoid) {
            vouchersValue += item.sellPrice;
          }
        });

        vouchersValue = vouchersValue * -1;

        // SPLITPAYMENT
        let splitPayments = [];

        if (this.invoiceData.typeOfPayment === 7) {
          for (let payment in this.invoiceData.splitPaymentObj) {
            if (!this.invoiceData.splitPaymentObj.hasOwnProperty(payment))
              continue;

            let label = payment;
            label = label.replace('1', "BAR");
            label = label.replace('2', "GUTSCHEIN");
            label = label.replace('3', "EC");
            label = label.replace('4', "RECHNUNG");
            label = label.replace('7', "SPLIT");
            label = label.replace('9', "KREDITKARTE");

            splitPayments.push({
              label: label,
              amount: parseFloat(this.invoiceData.splitPaymentObj[payment])
            })
          }
        }

        //GENERATE INVOICE DATA
        printData = createInvoicePrintingData(invoicePositions, {
          reprint: true,
          customer: this.invoiceCustomerData,
          saleID: this.invoiceNo,
          cashierID: this.invoiceData.cashierID,
          userID: this.invoiceData.sellerID,
          ts: this.invoiceData.invoiceTimestamp,
          tableNo: this.invoiceData.tableName,
          moneyGiven: this.invoiceData.moneyGiven,
          paymentType: this.invoiceData.typeOfPayment,
          splitPayments: splitPayments
        }, this.invoicePrinter.options, this.invoiceData.sellerData, false, false, goodsValue, vouchersValue);

        if (this.invoiceData.tableName != 0 || this.invoiceData.tableName.length > 0) {
          let today = new Date();
          let current_date = today.getDate() + "." + (today.getMonth() + 1) + "." + today.getFullYear();
          let current_city = "";

          if (this.settings.settings.hasOwnProperty("systemCity"))
            current_city = this.settings.settings.systemCity;

          printData.push({
            text: "\n-----------------------------------\n" + geol.t('generic.lang_hospitalityExpensesInformationAccordingTo') + " \n" + geol.t('generic.lang_paragraph4Paragraph5Number2EStG') + " \n-----------------------------------\n" + geol.t('generic.lang_hostedPersons') + ":\n\n...................................\n\n...................................\n\n...................................\n\n...................................\n\n...................................\n\n...................................\n\n...................................\n\n\n" + geol.t('generic.lang_occasionOfTheHospitality') + ":\n...................................\n\n...................................\n\n\n" + geol.t('generic.lang_amountOfExpenditure') + ":\n\n...................................\n" + geol.t('generic.lang_whenCateringInTheRestaurant') + "\n\n...................................\n" + geol.t('generic.lang_inOtherCases') + "\n\n\n" + geol.t('generic.lang_date_place') + ':' + current_date + " / " + current_city + "\n\n...................................\n" + geol.t('generic.lang_signature') + "\n\n\n\n"
          });
        }
        if (this.invoiceData.fiscalJSONObj !== null) {
          //FIND LAST CUT OF PRINT DATA(INDEX)
          let lastCutIndex = null;

          printData.forEach((element, index) => {
            if (element.hasOwnProperty("cut"))
              if (element.cut)
                lastCutIndex = index;
          });

          try {
          //DELETE LAST CUT, BECAUSE WE WILL ADD CUT AFTER TSE DATA
          if (lastCutIndex !== null)
            this.$delete(printData, lastCutIndex);
            printData.push({
              fontSize: 1
            });

            printData.push({
              font: "B"
            });

            let fiscalJSON = this.invoiceData.fiscalJSONObj;

            if (fiscalJSON.hasOwnProperty("firstOrder")) {
              printData.push({
                text: "1. " + geol.t('generic.lang_best') + ".: " + fiscalJSON.firstOrder.orderCreated
              });
            }

            if (fiscalJSON.hasOwnProperty("tse")) {
              //PRINT EPOS
              printData.push({
                text: geol.t('accounting.lang_eazSerNr') + ": " + this.api.auth.posID + " - " + this.api.auth.cashierID
              });
              printData.push({
                text: geol.t('accounting.lang_tseSerNr') + ": " + fiscalJSON.tse.serialNumber
              });
              printData.push({
                text: geol.t('accounting.lang_tseStart') + ": " + (Number(fiscalJSON.transaction.startTime)?moment.unix(fiscalJSON.transaction.startTime).format("DD.MM.YYYY HH:mm:ss"):fiscalJSON.transaction.startTime),
              });
              printData.push({
                text: geol.t('accounting.lang_tseStop') + ":  " + (Number(fiscalJSON.transaction.stopTime)?moment.unix(fiscalJSON.transaction.stopTime).format("DD.MM.YYYY HH:mm:ss"):fiscalJSON.transaction.stopTime),
              });
              printData.push({
                text: geol.t('accounting.lang_tseTrxNr') + ": " + fiscalJSON.transaction.transactionNumber
              });
              printData.push({
                text: geol.t('accounting.lang_tseSigCount') + ":" + fiscalJSON.transaction.signatureCounter
              });
              printData.push({
                text: geol.t('accounting.lang_signatur') + ":  " + fiscalJSON.transaction.signature
              });

              printData.push({
                align: "center"
              });

              if(!(this.invoicePrinter.options && this.invoicePrinter.options.hasOwnProperty('fiscalQrCode') && Number(this.invoicePrinter.options.fiscalQrCode) > 0)){
                //ADD QR CODE
                printData.push({
                  qrCode: fiscalJSON.qrCode
                });
              }


              printData.push({
                cut: true
              });
            } else {
              //PRINT FISKALY
              if(this.$store.getters['permissions/checkModule'](74)) {
                if(fiscalJSON && fiscalJSON.qr_code_data){
                  //PRINT FISKALY AUSTRIA
                  printData.push({
                    fontSize: 1,
                  });

                  printData.push({
                    font: 'B',
                  });
                  printData.push({
                    align: 'center',
                  });

                  //ADD QR CODE
                  printData.push({
                    qrCode: fiscalJSON.qr_code_data,
                  });

                  printData.push({
                    align: 'left',
                  });
                  printData.push({
                    text: geol.t('generic.lang_cashierID') + ' : ' + fiscalJSON.cash_register_serial_number,
                  });
                  printData.push({
                    text: 'Belegnummer : ' + fiscalJSON.receipt_number,
                  });
                }else{
                  printData.push({
                    text: 'Sicherheitseinrichtung ausgefallen',
                  });
                }
              }else {

                printData.push({
                  fontSize: 1
                });

                printData.push({
                  font: "B"
                });
                printData.push({
                  text: geol.t('accounting.lang_eazSerNr') + ": " + fiscalJSON.client_id
                });
                printData.push({
                  text: geol.t('accounting.lang_tseSerNr') + ": " + fiscalJSON.tss_serial_number
                });
                printData.push({
                  text: geol.t('accounting.lang_tseStart') + ": " + (Number(fiscalJSON.time_start)?moment.unix(fiscalJSON.time_start).format("DD.MM.YYYY HH:mm:ss"):fiscalJSON.time_start),
                });
                printData.push({
                  text: geol.t('accounting.lang_tseStop') + ":  " + (Number(fiscalJSON.time_end)?moment.unix(fiscalJSON.time_end).format("DD.MM.YYYY HH:mm:ss"):fiscalJSON.time_end),
                });
                printData.push({
                  text: geol.t('accounting.lang_tseTrxNr') + ": " + fiscalJSON.number
                });
                printData.push({
                  text: geol.t('accounting.lang_tseSigCount') + ":" + fiscalJSON.signature?.counter
                });
                printData.push({
                  text: geol.t('accounting.lang_signatur') + ":  " + fiscalJSON.signature?.value
                });

                printData.push({
                  align: "center"
                });
                if(!(this.invoicePrinter.options && this.invoicePrinter.options.hasOwnProperty('fiscalQrCode') && Number(this.invoicePrinter.options.fiscalQrCode) > 0)){
                  //ADD QR CODE
                  printData.push({
                    qrCode: fiscalJSON.qr_code_data
                  });
                }
              }

              printData.push({
                cut: true
              });

            }
          } catch (err) {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_tseError') + ':' + err,
              color: "error"
            });
            return;
          }
        } else {
          //CHECK IF LAST ELEMENT IS CUT
          let lastElememt = printData[(printData.length - 1)];

          if (!lastElememt.hasOwnProperty("cut"))
            printData.push({cut: true});
        }
        printData = this.addZVTPrinting(printData);
        printData = this.addCMIPrinting(printData);
      }

      //RETOURE DRUCK
      if (this.invoiceData.invoiceType === 3) {
        //CALCULATE INVOICE TOTAL SUM + RE-MAP INVOICE POSITIONS
        let invoiceTotal = 0;
        let invoicePositions = [];

        this.items.forEach((item) => {
          //TOTAL SUM
          invoiceTotal += item.unitPrice * item.qty;

          invoicePositions.push({
            qty: item.qty,
            name: item.descr,
            tax: item.tax,
            sellPrice: item.unitPrice
          });
        }, 0);

        //GET PRINTING XML DATA
        printData = createRetourePrintingData(invoicePositions, {
          total: invoiceTotal,
          saleID: this.invoiceNo,
          cashierID: this.invoiceData.cashierID,
          userID: this.invoiceData.sellerID,
          ts: this.invoiceData.invoiceTimestamp,
        }, this.invoicePrinter.paperType);

        if (this.invoiceData.fiscalJSONObj !== null) {
          //FIND LAST CUT OF PRINT DATA(INDEX)
          let lastCutIndex = null;

          printData.forEach((element, index) => {
            if (element.hasOwnProperty("cut"))
              if (element.cut)
                lastCutIndex = index;
          });

          try {
          //DELETE LAST CUT, BECAUSE WE WILL ADD CUT AFTER TSE DATA
          if (lastCutIndex !== null)
            this.$delete(printData, lastCutIndex);
            printData.push({
              fontSize: 1
            });

            printData.push({
              font: "B"
            });

            let fiscalJSON = this.invoiceData.fiscalJSONObj;

            if (fiscalJSON.hasOwnProperty("firstOrder")) {
              printData.push({
                text: "1. " + geol.t('generic.lang_best') + ".: " + fiscalJSON.firstOrder.orderCreated
              });
            }

            if (fiscalJSON.hasOwnProperty("tse")) {
              //PRINT EPOS
              printData.push({
                text: geol.t('accounting.lang_eazSerNr') + ": " + this.api.auth.posID + " - " + this.api.auth.cashierID
              });
              printData.push({
                text: geol.t('accounting.lang_tseSerNr') + ": " + fiscalJSON.tse.serialNumber
              });
              printData.push({
                text: geol.t('accounting.lang_tseStart') + ": " + (Number(fiscalJSON.transaction.startTime)?moment.unix(fiscalJSON.transaction.startTime).format("DD.MM.YYYY HH:mm:ss"):fiscalJSON.transaction.startTime),
              });
              printData.push({
                text: geol.t('accounting.lang_tseStop') + ":  " + (Number(fiscalJSON.transaction.stopTime)?moment.unix(fiscalJSON.transaction.stopTime).format("DD.MM.YYYY HH:mm:ss"):fiscalJSON.transaction.stopTime),
              });
              printData.push({
                text: geol.t('accounting.lang_tseTrxNr') + ": " + fiscalJSON.transaction.transactionNumber
              });
              printData.push({
                text: geol.t('accounting.lang_tseSigCount') + ":" + fiscalJSON.transaction.signatureCounter
              });
              printData.push({
                text: geol.t('accounting.lang_signatur') + ":  " + fiscalJSON.transaction.signature
              });

              printData.push({
                align: "center"
              });

              if(!(this.invoicePrinter.options && this.invoicePrinter.options.hasOwnProperty('fiscalQrCode') && Number(this.invoicePrinter.options.fiscalQrCode) > 0)){
                //ADD QR CODE
                printData.push({
                  qrCode: fiscalJSON.qrCode
                });
              }


              printData.push({
                cut: true
              });
            } else {
              //PRINT FISKALY
              if(this.$store.getters['permissions/checkModule'](74)) {
                if(fiscalJSON && fiscalJSON.qr_code_data){
                  //PRINT FISKALY AUSTRIA
                  printData.push({
                    fontSize: 1,
                  });

                  printData.push({
                    font: 'B',
                  });
                  printData.push({
                    align: 'center',
                  });

                  //ADD QR CODE
                  printData.push({
                    qrCode: fiscalJSON.qr_code_data,
                  });

                  printData.push({
                    align: 'left',
                  });
                  printData.push({
                    text: geol.t('generic.lang_cashierID') + ' : ' + fiscalJSON.cash_register_serial_number,
                  });
                  printData.push({
                    text: 'Belegnummer : ' + fiscalJSON.receipt_number,
                  });
                }else{
                  printData.push({
                    text: 'Sicherheitseinrichtung ausgefallen',
                  });
                }
              }else {

                printData.push({
                  fontSize: 1
                });

                printData.push({
                  font: "B"
                });
                printData.push({
                  text: geol.t('accounting.lang_eazSerNr') + ": " + fiscalJSON.client_id
                });
                printData.push({
                  text: geol.t('accounting.lang_tseSerNr') + ": " + fiscalJSON.tss_serial_number
                });
                printData.push({
                  text: geol.t('accounting.lang_tseStart') + ": " + (Number(fiscalJSON.time_start)?moment.unix(fiscalJSON.time_start).format("DD.MM.YYYY HH:mm:ss"):fiscalJSON.time_start),
                });
                printData.push({
                  text: geol.t('accounting.lang_tseStop') + ":  " + (Number(fiscalJSON.time_end)?moment.unix(fiscalJSON.time_end).format("DD.MM.YYYY HH:mm:ss"):fiscalJSON.time_end),
                });
                printData.push({
                  text: geol.t('accounting.lang_tseTrxNr') + ": " + fiscalJSON.number
                });
                printData.push({
                  text: geol.t('accounting.lang_tseSigCount') + ":" + fiscalJSON.signature?.counter
                });
                printData.push({
                  text: geol.t('accounting.lang_signatur') + ":  " + fiscalJSON.signature?.value
                });

                printData.push({
                  align: "center"
                });
                if(!(this.invoicePrinter.options && this.invoicePrinter.options.hasOwnProperty('fiscalQrCode') && Number(this.invoicePrinter.options.fiscalQrCode) > 0)){
                  //ADD QR CODE
                  printData.push({
                    qrCode: fiscalJSON.qr_code_data
                  });
                }
              }

              printData.push({
                cut: true
              });

            }
          } catch (err) {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_tseError') + ':' + err,
              color: "error"
            });
            return;
          }
        } else {
          //CHECK IF LAST ELEMENT IS CUT
          let lastElememt = printData[(printData.length - 1)];

          if (!lastElememt.hasOwnProperty("cut"))
            printData.push({cut: true});
        }
      }

      //ADDING SCALE TEXT STANDARD
      if(this.$store.getters['scale/isEnabled']){
        printData.unshift({
          text:(this.$i18n.locale==='de'?' -kopie- ':' -copy- '),
        })
        printData.unshift({
          align:'center',
        })
        printData.unshift({
          fontSize:2,
        })
      }

      // REMOVE OPEN CASH DRAWER ELEMENT
      let cashDrawerElementIndex = printData.findIndex((el) => el.hasOwnProperty("cashDrawer"))
      if(cashDrawerElementIndex >= 0)
        this.$delete(printData, cashDrawerElementIndex);

      // EPSON EPOS PRINT
      printDataFromPrinter(this.invoicePrinter, printData).then(() => {

      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.loadingReprint = false;
      })
    },
    getData() {
      this.loading = true;
      this.invoiceUID = this.$route.params.id;

      this.axios.post(ENDPOINTS.ACCOUNTING.DISPLAYINVOICE.GET, {
        invoiceID: this.invoiceUID
      }).then((res) => {
        if (res.data.success) {
          this.loading = false;
          this.additionalJSON = res.data.data.invoiceData.additionalJSON;
          this.invoiceUUID = res.data.data.invoiceData.invoiceUUID;
          this.orderNo = res.data.data.invoiceData.invoiceSaleID;
          this.invoiceCashierId = res.data.data.invoiceData.cashierID;
          this.invoiceNo = "" + res.data.data.invoiceData.invoiceSaleRealm + res.data.data.invoiceData.invoiceSaleID + res.data.data.invoiceData.invoiceRandomizerID;
          this.invoiceNoReal = res.data.data.invoiceData.invoiceSaleID;
          this.saleTimeStamp = res.data.data.invoiceData.saleTimeStamp;
          this.invoicePaidTimeStamp = res.data.data.invoiceData.invoicePaidTimeStamp;
          this.invoiceDueTimeStamp = res.data.data.invoiceData.invoiceDueTimeStamp;
          this.shippingType = res.data.data.invoiceData.shippingType;
          this.invoiceTotalTax = res.data.data.invoiceData.totalTax;
          this.invoiceFreeText = res.data.data.invoiceData.invoiceFreeText;
          this.deliveryTimeStamp = res.data.data.invoiceData.deliveryTimeStamp;
          this.invoiceTotal = res.data.data.invoiceData.invoiceTotal;
          this.invoiceTotalNet = res.data.data.invoiceData.invoiceTotalNet;
          this.items = res.data.data.invoicePositionData;
          this.invoiceCustomerData = res.data.data.invoiceData.customer_data;
          this.invoiceShippingData = res.data.data.invoiceData.shippingData;
          this.invoiceLogoUrl = res.data.data.invoiceData.invoiceLogoURL;
          this.invoiceSenderAddress = res.data.data.invoiceData.invoiceSenderInfo;
          this.invoiceData = res.data.data.invoiceData;
          this.retourOriginalInvoiceID = res.data.data.invoiceData.RetourOriginalInvoiceID;
          this.posVersion = res.data.data.invoiceData.posVersion;
          this.posType = (res.data.data.invoiceData.posVersion === 'GASTRO' || res.data.data.invoiceData.posVersion === 'DELIVERY') ? 'gastro' : (res.data.data.invoiceData.posVersion === 'RETAIL') ? 'retail' : (res.data.data.invoiceData.posVersion === 'FACTURE') ? 'retail' : console.log("none");

        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    voidInvoice() {


      this.axios.post(ENDPOINTS.POS.ACTIVESHIFT.GET, {}).then((res) => {
        if (res.data.success) {
          let navRoute = "";
          //NAV TO RETAIL IF RETAIL MODULE IS ACTIVE
          if (this.$store.getters['permissions/checkModule'](1)) {
            navRoute = '/pos/retail';

          }
          //NAV TO GASTRO IF GASTRO MODULE IS ACTIVE
          else if (this.$store.getters['permissions/checkModule'](2)) {
            // CHECK IF WE HAVE SAVED TABLE OR NOT
            // SET TRESENVERKAUF
            navRoute = '/pos/gastro';
          }

          this.$router.push({
            path: navRoute,
            query: {
              voidInvoice: this.invoiceNo
            }
          });
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || res.data.status,
            color: "error"
          });
        }
      });
    },
    resetData() {
      this.showCreate = false;
      this.showUpdate = false;
      this.showTable = true;
      this.id = null;
      this.text = "";
    },

    showList() {
      this.resetData();
      this.tab = 0;
    },
    isInvoiceEmpty(posType) {
      if (posType === "retail")
        if (this.pos.retail.orderedItems.length > 0)
          return false;

      if (posType === "gastro")
        if (this.pos.gastro.party !== null)
          if (this.pos.gastro.party.hasOwnProperty("name"))
            if (this.pos.gastro.openItems.hasOwnProperty(this.pos.gastro.party.name))
              if (this.pos.gastro.openItems[this.pos.gastro.party.name].length > 0)
                return false;

      return true;
    },

    checkTSEReactivateOrder() {
      this.loadingReActiveSale = true;

      //CHECK IF TSE CLIENT IS SETUP
      if (this.fiscalClient === null) {
        this.reActivateOrder(false);
        return;
      }

      if (this.fiscalClient.device.type === null) {
        this.fiscalClient.device.type = "epsonTSE";
      }
      // IF WE USE CLOUD TSE THE ENTRY IS BEEING FISCALIZED IN BACKEND
      if (this.fiscalClient.device.type !== "epsonTSE") {
        this.reActivateOrder(false);
        return;
      }


      //CHECK IF TSE DEVICE ALREADY ADDED
      let tsePrinters = this.$eposClass.getTSEPrinters();

      if (!tsePrinters.hasOwnProperty(this.fiscalClient.id)) {
        //ADD NEW TSE PRINTER
        this.$eposClass.addTSEPrinter(new this.$epson.ePOSDevice(), {
          id: this.fiscalClient.id,
          TSEProxyIPAdress: this.fiscalClient.device.TSEProxyIPAdress,
          ip: this.fiscalClient.device.ip,
          port: this.fiscalClient.device.port,
          deviceID: this.fiscalClient.device.deviceID,
          adminID: 'Administrator',
          clientID: this.fiscalClient.clientID
        }, false, false, (this.fiscalClient.device.useTSEProxy === 1));

        this.tseDevice = this.$eposClass.getTSEPrinter(this.fiscalClient.id);
      } else {
        this.tseDevice = this.$eposClass.getTSEPrinter(this.fiscalClient.id);

        if (this.tseDevice?.tseReady) {
          //CREATE ENTRY
          this.reActivateOrder();
        } else {

          //TSE WAS ADDED BUT ISNT READY YET
          this.loadingReActiveSale = false;
          this.tseErrorMessage = this.$t('generic.lang_tseIsNotReadyTryAgain');
          this.reActivateOrder(false);

        }
      }
    },

    reActivateOrder(withTSE = true) {

      if (!this.isInvoiceEmpty(this.posType)) {
        this.showDialog = true;
        return;
      }

      if (!this.posType) {
        return;
      }
      this.loadingReActiveSale = true;
      this.axios.post('create/reactivateSale/', {
        returnSaleID: this.invoiceUID
      }).then((res) => {

        if (res.data.status === 'SUCCESS') {

          this.returnedReactiveInvoiceorderId = res.data.orderId;
          this.returnedReactiveInvoiceorderParty = res.data.party;
          this.returnedReactiveInvoiceItems = res.data.tseInvoicePositions;
          this.reactivatePaymentType = res.data.paymentType;
          if (withTSE)
            this.startTransaction();
          this.returnedReactiveInvoiceId = res.data.invoiceRetourID;
          if (!withTSE) {
            this.finishCreating();
          }
          this.loadingReActiveSale = false;


        } else if (res.data.status === 'FAILED') {
          Events.$emit("showSnackbar", {
            message: res.data.msg || res.data.status,
            color: "error"
          });
        }

      }).catch(err => {
        console.log(err);
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {

      })
    },

    finishCreating(startTransactionResult = null, finishTransactionResult = null) {
      //SAVE FISCAL DATA INTO DATABASE
      if (startTransactionResult !== null && finishTransactionResult !== null) {
        let payload = {
          fiscalDevice: {
            id: this.fiscalClient.device.id,
            deviceID: this.fiscalClient.device.deviceID
          },
          fiscalClient: {
            id: this.fiscalClient.id,
            clientID: this.fiscalClient.clientID
          },
          tse: {
            serialNumber: this.tseDevice.storageInfo.tseInformation.serialNumber,
          },
          transaction: {
            processType: "Kassenbeleg-V1",
            startTime: startTransactionResult.output.logTime,
            stopTime: finishTransactionResult.output.logTime,
            transactionNumber: startTransactionResult.output.transactionNumber,
            signatureCounter: finishTransactionResult.output.signatureCounter,
            signature: finishTransactionResult.output.signature
          }
        };

        //SAVE FISCAL DATA INTO DATABASE
        this.axios.post(ENDPOINTS.POS.SALE.UPDATETSEPAYLOAD, {
          invoiceUUID: this.returnedReactiveInvoiceId,
          tsePayload: payload,
        }).then(() => {
          this.loadingReActiveSale = false;
          if (this.invoiceData.tableName === "0" || this.invoiceData.tableName === "") {
            this.getOrder(this.returnedReactiveInvoiceorderId)

          } else {
            this.getTableOrder(this.returnedReactiveInvoiceorderId, this.returnedReactiveInvoiceorderParty, this.posVersion === 'DELIVERY')
          }
        }).catch(() => {
          this.loadingReActiveSale = false;
        });
      } else {
        this.loadingReActiveSale = false;
        if (this.invoiceData.tableName === "0" || this.invoiceData.tableName === "") {
          this.getOrder(this.returnedReactiveInvoiceorderId)

        } else {
          this.loadingReActiveSale = false;
          this.getTableOrder(this.returnedReactiveInvoiceorderId, this.returnedReactiveInvoiceorderParty, this.posVersion === 'DELIVERY')
        }
      }
    },


    getOrder(orderID) {
      this.axios.post(ENDPOINTS.POS.RETAIL.ORDER.GET, {
        orderID: orderID
      }).then((res) => {
        if (res.data.success) {
          if (this.posType === 'gastro') {

            this.$store.commit("pos/gastro/clearTableParties");

            this.$store.dispatch("pos/gastro/setTable", {
              table: {
                name: 0,
                parties: [
                  {
                    name: this.invoiceData.tablePartyName
                  }
                ]
              }
            })

            this.$store.commit("pos/gastro/setOpenItems", {
              orders: res.data.order.items,
              party: {
                name: this.invoiceData.tablePartyName
              },
            });

            this.$store.commit("pos/gastro/changeParty", {
              posType: this.posType,
              party: {
                name: this.invoiceData.tablePartyName
              }
            });

          } else {
            this.$store.commit("pos/retail/setItems", {
              orders: res.data.order.items
            });
          }
          //GET CUSTOMER
          if (res.data.order.customer !== null) {
            this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.GETEDIT, {
              customerID: res.data.order.customer.id
            }).then((res2) => {
              if (res2.data.success) {
                this.$store.dispatch("pos/setCustomer", {
                  posType: this.posType,
                  customer: res2.data.customer,
                  setCustomerOnly: true,
                })
              }
            }).catch((err) => {

            });
          }

          //REDIRECT TO POS
          this.$router.push({
            path: '/pos/' + this.posType,
            query: {
              reActivateSale: this.invoiceUID
            }
          });
        }
      }).catch(() => {

      })
    },
    getTableOrder(orderID, party, isDelivery = false) {
      let endpoint = ENDPOINTS.POS.RETAIL.ORDER.GET;
      let query = {};

      if (isDelivery) {
        query.useDeliveryService = '';
        //endpoint = ENDPOINTS.DELIVERY.ORDER.GET;
      }

      query.reActivateSale = this.invoiceUID;

      this.axios.post(ENDPOINTS.POS.RETAIL.ORDER.GET, {
        orderID: orderID
      }).then(async (resOrderData) => {
        if (resOrderData.data.success) {
          if (this.posType === 'gastro') {
            let table = {
              name: this.invoiceData.tableName,
              parties: [{name: party}],
              totalParties: 1,
              total: this.invoiceData.invoiceTotal,
            }
            this.$store.commit("pos/gastro/clearTableParties");
            if (isDelivery) {
              this.$store.dispatch("pos/gastro/setDeliveryService", true)
            } else {
              await this.axios.post(ENDPOINTS.POS.GASTRO.TABLES.GETROOMBYTABLE, {
                tableName: this.invoiceData.tableName,
              }).then(res3 => {
                if (res3.data.status) {
                  table.roomName = res3.data.roomName;
                }
              })
            }

            this.$store.dispatch("pos/gastro/setTable", {
              table: table
            })
            this.$store.commit("pos/gastro/setOrderedItems", {
              orders: resOrderData.data.order.items,
              partyName: party,
            });
            this.$store.commit("pos/gastro/changeParty", {
              posType: this.posType,
              party: {
                name: party
              }
            });

            let gastroOrder = {
              orders:{},
              customerId:resOrderData.data.order.customer?resOrderData.data.order.customer.id:0,
            }
            gastroOrder.orders[party] = resOrderData.data.order.items;
            this.$store.dispatch('pos/gastro/setGastroOrder',gastroOrder);

          }

          //GET CUSTOMER
          if (resOrderData.data.order.customer !== null) {
            this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.GETEDIT, {
              customerID: resOrderData.data.order.customer.id
            }).then((res2) => {
              if (res2.data.success) {
                this.$store.dispatch("pos/setCustomer", {
                  posType: this.posType,
                  customer: res2.data.customer,
                  setCustomerOnly: true,
                })
              }
            }).catch((err) => {

            });
          }

          //REDIRECT TO POS
          this.$router.push({
            path: '/pos/gastro',
            query: query,
          });
        }
      }).catch(() => {

      })
    },


    startTransaction() {
      this.tseDevice?.GFE_StartTransaction(this.fiscalClient.clientID);

      Events.$once("GFE_OnReceive", (payload) => {
        if (payload.result.function === "StartTransaction") {
          if (payload.result.result === "EXECUTION_OK") {
            this.finishTransaction(payload.result);
          } else {
            this.tseErrorMessage = this.$t('generic.lang_couldNotCompleteSigning') + ": " + payload.result.result;
            this.tseErrorDialogButton = "close";
          }
        }
      });
    },
    finishTransaction(startTransactionResult) {
      this.tseDevice.finishInvoiceTransaction(this.fiscalClient.clientID, startTransactionResult.output, this.returnedReactiveInvoiceItems, this.reactivatePaymentType, []);

      Events.$once("GFE_OnReceive", (payload) => {
        if (payload.result.function === "FinishTransaction") {
          if (payload.result.result === "EXECUTION_OK") {
            this.finishCreating(startTransactionResult, payload.result);
          } else {
            this.tseErrorMessage = this.$t('generic.lang_couldNotCompleteSigning') + ": " + payload.result.result;
            this.tseErrorDialogButton = "close";
          }
        }
      });
    },

    activeUserID() {
      if (this.multiUser)
        if (this.activeUser.userID !== null)
          return parseInt(this.activeUser.userID);

      return this.user.userID;
    },
    closeTSEErrorDialog() {
      if (this.tseErrorDialogButton === "close") {
        this.$emit("entryCreated");
      } else {
        this.tseErrorMessage = "";
      }
    },

    // -----------------------------------------------
    // ------------------ GFE / TSE ------------------
    // ------------------ CALLBACKS ------------------
    // -----------------------------------------------
    eposDeviceCallback(payload) {
      // IMPORTANT
      if (!this.showDialog)
        return;

      if (payload.action === "createDeviceCallback.error") {
        this.tseErrorMessage = `${this.$t('generic.lang_setupOfTheTseCouldNotBeStarted')}<br>(${payload.code})`;
      }

      if (payload.action === "connectCallback.error") {
        //CONNECTION ERROR
        this.tseErrorMessage = this.$t('generic.lang_connectionToTseCouldNotBeEstablishedCheckIp')
      }

      if (payload.action === "disconnect") {
        this.tseDevice = null;
      }
    },
    GFE_OnReceiveCallback(payload) {
      // IMPORTANT
      if (!this.showDialog)
        return;

      if (payload.result.function !== "StartTransaction") {
        if (payload.result.function === "GetStorageInfo") {
          //TSE FINALLY FINISHED CONNECTING
          //START INSERTING CASHBOOK ENTRY
          this.reActivateOrder(true);
        } else {
          if (payload.result.result !== "EXECUTION_OK") {
            //OTHER ERROR OCCURED -> ABORTING
            this.tseErrorMessage = `${this.$t('generic.lang_errorOccurred')}<br>(${payload.result.result})`;
          }
        }
      }
    }

  }
}
</script>